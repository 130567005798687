import {
  AzureCommunicationTokenCredential,
  CommunicationUserIdentifier,
} from '@azure/communication-common';
import {
  AvatarPersonaData,
  AzureCommunicationCallAdapterArgs,
  AzureCommunicationOutboundCallAdapterArgs,
  CallAdapter,
  CallAdapterState,
  fromFlatCommunicationIdentifier,
  useAzureCommunicationCallAdapter,
} from '@azure/communication-react';
import { usePicture } from '@guider-global/azure-storage-hooks';
import { useProfiles, useRelationships } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import VideoRoom, { VideoRoomError } from 'components/VideoRoom';
import { useLocalization, useSessions, useVideoParticipants } from 'hooks';
import { useVideoTokens } from 'hooks/useVideoTokens';
import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getVideoRoomState,
  setCallCompositePage,
} from 'store/slices/videoRoomSlice';

const VideoPage: FC = () => {
  // Organization and Base language
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({
    localeCode,
    options: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onError: (err) => {
        setError({
          message: 'Error Getting Languages',
          techMsg: err.message,
        });
      },
    },
  });

  // Local State
  const [joinedAt, setJoinedAt] = useState<Date>();
  const [submittedTimeInCall, setSubmittedTimeInCall] =
    useState<boolean>(false);

  const [error, setError] = useState<VideoRoomError>();

  // React Router
  const { id: sessionId } = useParams();

  // REDUX
  const dispatch = useAppDispatch();
  const videoRoomState = useAppSelector(getVideoRoomState);
  const { transitionSidebar, callCompositePage } = videoRoomState;

  // Hooks
  const { getImage, loading: loadingPicture } = usePicture({
    containerName: 'pictures',
  });

  const { sessions } = useSessions({
    url: sessionId ? `sessions/${sessionId}` : undefined,
    options: {
      onError: (err) => {
        console.error('Error getting sessions', err);
        setError({
          message: 'Error Getting Profile data',
          techMsg: err.message,
          httpCode: err.code,
        });
      },
    },
  });
  const session = sessions?.at(0);

  const { profile } = useProfiles({
    options: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onError: (err) => {
        console.error('Error getting Profiles', err);
        setError({
          message: 'Error Getting Profile data',
          techMsg: err.message,
          httpCode: err.code,
        });
      },
    },
  });
  const displayName = profile?.displayName;
  const communicationUserId = profile?.communicationUserId;
  const roomId = session?.roomId;
  const relationshipId = session?.relationshipId;
  const sessionName = session?.name;

  const {
    videoParticipants,
    reqVideoParticipants,
    isLoadingVideoParticipants,
  } = useVideoParticipants({
    params: {
      session: sessionId,
    },
    options: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onError: (err) => {
        console.error('Error Video participants', err);
        setError({
          message: 'Error Getting Video participant data',
          techMsg: err.message,
          httpCode: err.code,
        });
      },
    },
    mutateOptions: {
      onError: (err) => {
        console.error('Error Updating participants', err);
        setError({
          message: 'Error Updating video participant',
          techMsg: err.message,
          httpCode: err.code,
        });
      },
    },
  });
  const videoParticipant = videoParticipants?.find(
    (videoParticipant) => videoParticipant.session === sessionId,
  );

  // Relationship
  const { relationships, isLoadingRelationships } = useRelationships({
    url: relationshipId ? `/relationships/${relationshipId}` : undefined,
    options: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onError: (err) => {
        console.error('Error getting relationships', err);
        setError({
          message: 'Error Getting Relationship session data',
          techMsg: err.message,
          httpCode: err.code,
        });
      },
    },
  });

  const relationship = relationships?.find(
    (relationship) => relationship.id === relationshipId,
  );
  const relationshipProfiles = [
    ...(relationship?.traineeProfiles ?? []),
    ...(relationship?.guideProfiles ?? []),
  ];

  const handleFetchUserImages = async (
    communicationUserId: string,
  ): Promise<AvatarPersonaData> => {
    const profile = relationshipProfiles.find(
      (profile) => profile?.communicationUserId === communicationUserId,
    );
    const user = relationship?.users?.find(
      (user) => user?.id === profile?.userId,
    );

    if (!user) return {};
    const imageUrl = profile?.picture
      ? getImage(profile?.picture)
      : user.picture;
    return { imageUrl };
  };

  // Users Video Token
  const { videoTokens } = useVideoTokens({
    options: {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      onError: (err) => {
        console.error('Error getting video tokens', err);
        setError({
          message: 'Error Guider video authentication token',
          techMsg: err.message,
          httpCode: err.code,
        });
      },
    },
  });

  const { token } = videoTokens?.[0] ?? {};

  // CMS

  const sanityBaseLanguageVideo =
    baseLanguage?.relationships?.sessions?.sessions_video;
  const {
    end_session_button_label: exitSessionLabel,
    toggle_chat_button_label: chatLabel,
    toggle_goals_button_label: goalsLabel,
    toggle_schedule_session_button_label: scheduleSessionLabel,
  } = sanityBaseLanguageVideo ?? {};

  // ACS Call Adapter
  const credential = useMemo(() => {
    try {
      if (!token) return undefined;
      return new AzureCommunicationTokenCredential(token);
    } catch (error) {
      console.error('Error constructing token credential', error);

      if (error instanceof Error) {
        setError({
          message: 'Failed to construct token credential',
          techMsg: error.message,
        });
      } else {
        setError({
          message: 'Failed to construct token credential',
          techMsg: `${error}`,
        });
      }
      return undefined;
    }
  }, [token]);

  const callAdapterArgs: Partial<AzureCommunicationCallAdapterArgs> =
    useMemo(() => {
      if (!roomId || !communicationUserId) return {};
      const args: Partial<
        | AzureCommunicationCallAdapterArgs
        | AzureCommunicationOutboundCallAdapterArgs
      > = {
        userId: fromFlatCommunicationIdentifier(
          communicationUserId,
        ) as CommunicationUserIdentifier,
        displayName,
        credential,
        locator: { groupId: roomId },
      };
      return args;
    }, [communicationUserId, credential, displayName, roomId]);

  const afterCreate = async (adapter: CallAdapter): Promise<CallAdapter> => {
    adapter.on('error', (err) => {
      console.error('Error after call adapter create', err);
      setError({ message: 'Error Join Session', techMsg: err.message });
    });
    const handlePageChange = (state: CallAdapterState) => {
      dispatch(setCallCompositePage(state.page));
    };
    adapter.onStateChange(async (state) => {
      handlePageChange(state);
    });
    return adapter;
  };

  const callAdapter = useAzureCommunicationCallAdapter(
    callAdapterArgs,
    afterCreate,
  );

  useEffect(() => {
    if (!callAdapter) return;
    const disposeAdapter = async (): Promise<void> => {
      if (videoParticipant && joinedAt && !submittedTimeInCall) {
        setSubmittedTimeInCall(true);

        await reqVideoParticipants({
          method: 'PATCH',
          url: `/videoParticipants/${videoParticipant.id}`,
          data: {
            joinedAt,
            leftAt: new Date(),
          },
        });
      }

      callAdapter?.dispose();
    };

    window.addEventListener('beforeunload', disposeAdapter);
    return () => {
      window.removeEventListener('beforeunload', disposeAdapter);
    };
  }, [
    callAdapter,
    joinedAt,
    reqVideoParticipants,
    submittedTimeInCall,
    videoParticipant,
  ]);

  useEffect(() => {
    if (isLoadingVideoParticipants) {
      return;
    }

    const handleCallCompositePage = async () => {
      if (callCompositePage === 'call') {
        const now = new Date();

        setSubmittedTimeInCall(false);

        if (!joinedAt || submittedTimeInCall) {
          setJoinedAt(now);
        }

        if (!videoParticipant && profile && sessionId && joinedAt) {
          await reqVideoParticipants({
            method: 'POST',
            url: '/videoParticipants',
            data: {
              profile: profile.id,
              relationship: relationshipId,
              session: sessionId,
              joinedAt: now,
            },
          });
        }
      }

      if (
        callCompositePage === 'leftCall' &&
        videoParticipant &&
        joinedAt &&
        !submittedTimeInCall
      ) {
        setSubmittedTimeInCall(true);

        await reqVideoParticipants({
          method: 'PATCH',
          url: `/videoParticipants/${videoParticipant.id}`,
          data: {
            joinedAt,
            leftAt: new Date(),
          },
        });
      }
    };

    handleCallCompositePage();
  }, [
    callCompositePage,
    isLoadingVideoParticipants,
    joinedAt,
    profile,
    relationshipId,
    reqVideoParticipants,
    sessionId,
    submittedTimeInCall,
    videoParticipant,
  ]);

  // Derivations

  const loading =
    isLoadingRelationships ||
    !token ||
    !roomId ||
    !callAdapter ||
    loadingPicture;

  console.log('loading video page ', {
    isLoadingRelationships,
    isToken: Boolean(token),
    isRoomId: Boolean(roomId),
    isCallAdapter: Boolean(callAdapter),
    isLoadingPicture: loadingPicture,
  });

  return (
    <VideoRoom
      sessionTitle={sessionName ?? ''}
      exitSessionLabel={exitSessionLabel}
      relationshipId={relationshipId ?? ''}
      scheduleSessionLabel={scheduleSessionLabel}
      goalsLabel={goalsLabel}
      chatLabel={chatLabel}
      callAdapter={callAdapter}
      onFetchUserImages={handleFetchUserImages}
      localeCode={localeCode}
      transitionSidebar={transitionSidebar}
      loading={loading}
      error={error}
      onErrorClose={() => setError(undefined)}
    />
  );
};

export default VideoPage;
