import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks';
import { Box } from '@mui/material';
import { SettingsDescription, SettingsSubheader } from 'components';
import { AvailabilityForm } from 'forms';
import { useLocalization } from 'hooks';

export function SettingsAvailabilityPage() {
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const availability = baseLanguage?.settings?.availability;
  const {
    section_title: sectionTitle,
    section_description: sectionDescription,
  } = availability ?? {};

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        '& > .MuiTypography-h6': {
          mb: 0.5,
        },
      }}
    >
      <SettingsSubheader subheader={sectionTitle} />
      <SettingsDescription description={sectionDescription} />

      <AvailabilityForm />
    </Box>
  );
}
