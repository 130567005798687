import { Box } from '@mui/material';
import { PageWrapper } from 'components';
import { app as teamsApp, authentication } from '@microsoft/teams-js';
import { Loading } from '@guider-global/ui';

export const TeamsAuthPage = () => {
  const isTeamsInitialized = teamsApp.isInitialized();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  if (code) {
    isTeamsInitialized && authentication.notifySuccess(code);
  } else {
    isTeamsInitialized &&
      authentication.notifyFailure(
        'Authorization code not found in redirect URI.',
      );
  }

  return (
    <PageWrapper>
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Loading />
      </Box>
    </PageWrapper>
  );
};
