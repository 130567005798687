import { useProfiles } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  buildSanityImageUrl,
  useBaseLanguage,
  useSettings,
} from '@guider-global/sanity-hooks';
import { Stack, theme, TextStack, SkeletonStack } from '@guider-global/ui';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, ThemeProvider, useTheme } from '@mui/material';
import { ReviewsContainer } from 'containers/Settings/ReviewsContainer';
import { useLocalization, useReviews } from 'hooks';
import { useState } from 'react';
import { deepMerge } from 'utils';

export function SettingsReviewsPage() {
  // State

  const [value, setValue] = useState<string | number>('1');
  // Hooks
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const organizationTheme = useTheme();
  const palette = deepMerge(theme.appTheme.palette, organizationTheme.palette);

  // Settings
  const { settings, isLoadingSettings } = useSettings({
    localeCode,
  });

  const emptyStateAvatar = buildSanityImageUrl({
    source: settings?.static_media?.relationships?.no_relationships ?? '',
    width: 180,
  });

  // Base Language

  const { baseLanguage, isLoadingBaseLanguage } = useBaseLanguage({
    localeCode,
  });
  const reviewsText = baseLanguage?.settings?.reviews;

  // Profile

  const { profiles, isLoadingProfiles } = useProfiles({});
  const profile = profiles?.at(0);

  // Reviews
  const { reviews, isLoadingReviews } = useReviews({});

  const reviewsReceived = reviews?.filter(
    (review) => review.reviewedProfile.id === profile?.id,
  );
  const reviewsAuthored = reviews?.filter(
    (review) => review.authorProfile.id === profile?.id,
  );

  // Loading

  const isLoading = isLoadingReviews || isLoadingProfiles || isLoadingSettings;

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (isLoadingBaseLanguage) return <></>;

  return (
    <ThemeProvider theme={{ ...theme.appTheme, palette }}>
      <Stack direction={'column'} pb={3}>
        <TextStack
          size={'medium'}
          heading={{ text: reviewsText?.title, variant: 'h2' }}
          subtitles={[
            {
              text: reviewsText?.description,
              variant: 'body1',
              color: 'text.secondary',
            },
          ]}
        />
        {isLoading && (
          <SkeletonStack
            amount={2}
            direction={'column'}
            skeletonHeight={'150px'}
          />
        )}
        {!isLoading && (
          <TabContext value={value}>
            <TabList onChange={handleChange}>
              <Tab label={reviewsText?.about_user_tab_title} value="1" />
              <Tab label={reviewsText?.by_user_tab_title} value="2" />
            </TabList>
            <TabPanel value="1" sx={{ p: 0 }}>
              <ReviewsContainer
                reviews={reviewsReceived}
                emptyStateAvatar={emptyStateAvatar}
                variant="received"
              />
            </TabPanel>
            <TabPanel value="2" sx={{ p: 0 }}>
              <ReviewsContainer
                reviews={reviewsAuthored}
                emptyStateAvatar={emptyStateAvatar}
                variant="authored"
              />
            </TabPanel>
          </TabContext>
        )}
      </Stack>
    </ThemeProvider>
  );
}
