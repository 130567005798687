// external
import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Divider } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';

// hooks
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useRegisterInvite } from 'hooks/useRegisterInvite';
import {
  SessionStorageKeys,
  useLocalization,
  useMixpanelEvents,
  useSessionStorageTyped,
} from 'hooks';
import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';

// Store
import {
  selectRegistrationType,
  showAppAlert,
  showAppError,
  toggleModal,
} from 'store/slices/appSlice';

// Internal
import {
  RegisterLocalAccount,
  RegisterSSO,
  RenderDividerMessage,
  TypographyAction,
} from 'components';

import { validateEmail } from 'utils/validateEmail';
import { BaseHeader, OrganizationContentContainer } from '@guider-global/ui';

//  Types
import { URLQueryParams } from '@guider-global/shared-types';

// Utils
import { getOrigin, getSubDomain } from '@guider-global/front-end-utils';

const { REACT_APP_BASE_API_URL, REACT_APP_AUTH0_CLIENT_ID } = process.env;
const DATA_CY_PAGE = 'register_account';

export const RegisterAccountPage: FC = () => {
  // Auth0
  const { loginWithRedirect } = useAuth0();
  // Redux
  const registrationPageType = useAppSelector(selectRegistrationType);
  const dispatch = useAppDispatch();
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  // Router
  const navigate = useNavigate();
  const [urlSearchParams] = useSearchParams();

  // Util
  const origin = getOrigin();

  // Hooks
  const { trackScreenTime } = useMixpanelEvents();
  // Session storage
  const [, setRedirect] = useSessionStorageTyped<string>(
    SessionStorageKeys.REGISTER_REDIRECT,
  );
  // - Sanity base language
  const {
    isLoadingBaseLanguage,
    baseLanguage,
    isErrorBaseLanguage,
    errorBaseLanguage,
  } = useBaseLanguage({ localeCode });

  const baseLanguageLegalNotices = baseLanguage?.legal_notices;
  const privacyPolicyLabel =
    baseLanguageLegalNotices?.privacy_policy?.privacy_policy_label;
  const termsOfServiceLabel =
    baseLanguageLegalNotices?.terms_of_service?.terms_of_service_label;
  const baseLanguageRegistration = baseLanguage?.registration;
  const baseLanguageCommon = baseLanguageRegistration?.common;
  const registrationPageTitle = baseLanguageRegistration?.common?.title;

  const baseLanguageMultitenantSSO = baseLanguageRegistration?.multitenant_SSO;
  const baseLanguageRegistrationLocalAccounts =
    baseLanguageRegistration?.local_accounts;
  const baseLanguageRegistrationSSOAndLocalAccounts =
    baseLanguageRegistration?.sso_and_local_accounts;

  // - Sanity Organization
  const { organization } = useOrganization({
    organizationSlug,
  });

  const organizationAccess = organization?.access;
  const organizationEmailVerification = organizationAccess?.email_verification;
  const organizationConfigurations =
    organizationAccess?.configuration_array ?? [];
  const basicInfo = organization?.basic_info;
  const organizationId = basicInfo?.auth0_organization_id;
  const organizationName = basicInfo?.name;
  const landingPage = organization?.landing_page;
  const isLandingPageEnabled = landingPage?.enable_landing_page;

  const {
    error: inviteError,
    inviteUrl,
    loading: inviteLoading,
    handleGetInvite,
  } = useRegisterInvite();

  // Local State
  const [eventLoading, setEventLoading] = useState<boolean>(true);

  const [formErrors, setFormErrors] = useState<boolean>(false);
  const [email, setEmail] = useState<string>();
  const [disableButton, setDisableButton] = useState<boolean>(true);

  const loading = eventLoading || inviteLoading || isLoadingBaseLanguage;

  // Effects
  useEffect(() => {
    const redirectAddress = urlSearchParams.get(URLQueryParams.REDIRECT);
    if (!redirectAddress) return;
    setRedirect(redirectAddress);
  }, [setRedirect, urlSearchParams]);

  useEffect(() => {
    if (!email) return;
    const isEmail = validateEmail(email);
    if (!isEmail || !email) {
      setFormErrors(true);
      return;
    }
    setFormErrors(false);
    setDisableButton(false);
  }, [email]);
  useEffect(() => {
    if (REACT_APP_BASE_API_URL && REACT_APP_AUTH0_CLIENT_ID) {
      setEventLoading(false);
      return;
    }
    dispatch(
      showAppError({
        code: '418',
        message: 'Missing Env values',
        visible: true,
        overlay: true,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!inviteUrl) {
      return;
    }
    const invite = new URL(inviteUrl);
    const invitation = invite.searchParams.get('invitation');
    const organizationId = invite.searchParams.get('organization');
    const organizationName = invite.searchParams.get('organization_name');
    if (!invitation || !organizationId || !organizationName) {
      return;
    }
    loginWithRedirect({
      authorizationParams: {
        organization: organizationId,
        invitation,
        redirect_uri: `${origin}/login`,
      },
    });
  }, [inviteUrl, loginWithRedirect, origin]);

  useEffect(() => {
    if (inviteError) {
      dispatch(
        showAppAlert({
          severity: 'error',
          message: inviteError,
        }),
      );
    }
  }, [dispatch, inviteError]);

  useEffect(() => {
    if (!isErrorBaseLanguage) return;
    const errors = errorBaseLanguage;
    console.warn(errors);
    dispatch(
      showAppError({
        code: '418',
        message: 'Error occurred getting base languages',
        visible: true,
        overlay: true,
      }),
    );
  }, [dispatch, errorBaseLanguage, isErrorBaseLanguage]);

  useEffect(() => {
    if (loading) return;
    if (registrationPageType === 'none') {
      navigate('/error?error=404');
    }
  }, [loading, navigate, registrationPageType]);

  // Events
  const trackAuthSelect = useCallback(() => {
    trackScreenTime('Registration - Auth / Select Email or SSO');
  }, [trackScreenTime]);

  const handleEmailRegister = useCallback(() => {
    const isEmail = validateEmail(email);
    if (!isEmail || !email) {
      setFormErrors(true);
      return;
    }

    trackAuthSelect();
    setFormErrors(false);

    handleGetInvite({
      organizationId: organizationId ?? '',
      organizationName: organizationName ?? '',
      email,
    });
  }, [
    email,
    trackAuthSelect,
    handleGetInvite,
    organizationId,
    organizationName,
  ]);

  const handleSSORegister = useCallback(
    async (connection: string, organizationId: string) => {
      trackAuthSelect();
      setEventLoading(true);
      await loginWithRedirect({
        authorizationParams: {
          connection,
          organization: organizationId,
        },
        async openUrl(url) {
          window.location.href = url;
        },
      });
      setEventLoading(false);
    },
    [trackAuthSelect, loginWithRedirect],
  );

  const handleEmailChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setEmail(event.target.value);
    },
    [],
  );

  const handlePrivacyModal = useCallback(() => {
    dispatch(toggleModal('privacyPolicy'));
  }, [dispatch]);

  const handleTermsOfServiceModal = useCallback(() => {
    dispatch(toggleModal('termsOfService'));
  }, [dispatch]);

  const handleLogin = useCallback(() => {
    loginWithRedirect({
      authorizationParams: {
        organization: organizationId,
        redirect_uri: `${origin}/login`,
      },
    });
  }, [loginWithRedirect, organizationId, origin]);

  const handleBack = useCallback(() => {
    trackScreenTime(
      'Registration - Auth / Select Email or SSO - Go Back Button',
    );
    window.history.back();
  }, [trackScreenTime]);

  // Derivations

  const getSubheader = useCallback(() => {
    switch (registrationPageType) {
      case 'localAccountsAndSSO':
        return baseLanguageRegistrationSSOAndLocalAccounts?.description;
      case 'localAccounts':
        return baseLanguageRegistrationLocalAccounts?.description;
      case 'multitenantsSSO':
        return baseLanguageMultitenantSSO?.description;
      default:
        return '';
    }
  }, [
    baseLanguageMultitenantSSO?.description,
    baseLanguageRegistrationLocalAccounts?.description,
    baseLanguageRegistrationSSOAndLocalAccounts?.description,
    registrationPageType,
  ]);
  const subheader = getSubheader();

  if (registrationPageType === 'none') {
    return <></>;
  }

  return (
    <>
      <OrganizationContentContainer>
        <Box
          sx={{
            display: 'flex',
            flexFlow: 'column nowrap',
            justifyContent: 'center',
            alignContent: 'stretch',
            alignItems: 'stretch',
            gap: 3,
            maxWidth: '320px',
          }}
        >
          <BaseHeader
            data-cy={DATA_CY_PAGE}
            header={registrationPageTitle}
            subheader={subheader}
          />

          <RegisterSSO
            loading={loading}
            handleRegister={handleSSORegister}
            organizationId={organizationId ?? ''}
            organizationSSOConfigurations={organizationConfigurations}
          />

          <RenderDividerMessage
            label={
              baseLanguageRegistrationSSOAndLocalAccounts?.or_register_local_account_label
            }
            show={registrationPageType === 'localAccountsAndSSO'}
            data-cy={DATA_CY_PAGE}
          />

          <RegisterLocalAccount
            label={baseLanguageRegistrationLocalAccounts?.email_input_label}
            enableInfoAlert={organizationEmailVerification}
            infoMessage={
              baseLanguageRegistration?.common?.confirm_email_alert_label
            }
            buttonLabel={baseLanguage?.globals?.common?.continue_button_label}
            error={formErrors}
            loading={loading}
            onClickButton={handleEmailRegister}
            handleChange={handleEmailChange}
            disableButton={disableButton}
          />
          <Box>
            <TypographyAction
              actionLabel={termsOfServiceLabel}
              onClick={handleTermsOfServiceModal}
              data-cy={`${DATA_CY_PAGE}-terms-of-service`}
            >
              {baseLanguageCommon?.legal_notice_label}
            </TypographyAction>
            <TypographyAction
              actionLabel={privacyPolicyLabel}
              onClick={handlePrivacyModal}
              data-cy={`${DATA_CY_PAGE}-privacy-policy`}
            />
          </Box>
          <Divider />
          <TypographyAction
            actionLabel={baseLanguageCommon?.login_button_label}
            onClick={handleLogin}
            data-cy={`${DATA_CY_PAGE}-or-continue-email-header`}
          >
            {baseLanguageCommon?.have_account_label}
          </TypographyAction>
        </Box>
      </OrganizationContentContainer>
      {isLandingPageEnabled && (
        <Button
          variant="text"
          color="info"
          size="large"
          sx={{ mt: 4 }}
          onClick={handleBack}
        >
          {baseLanguage?.globals?.common?.go_back_button_label}
        </Button>
      )}
    </>
  );
};
