// external
import { Alert, Box, CircularProgress, Divider, Grow } from '@mui/material';
import { FC, useCallback, useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

// Internal Hooks
import { useLocalization, useMobileMediaQuery } from 'hooks';
import { useBaseLanguage, useOrganization } from '@guider-global/sanity-hooks';
import { useAxios } from '@guider-global/redux-axios-hooks';

// Utils
import { getSubDomain } from '@guider-global/front-end-utils';

// Local components
import { SettingsDescription, SettingsSubheader } from 'components';
import { BaseOutlinedTextField, BaseLoadingButton } from '@guider-global/ui';
import { AccountPreferencesForm } from 'forms';
import { useUsers } from '@guider-global/front-end-hooks';

const { REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN } = process.env;

export const SettingsAccountPage: FC = () => {
  // Styles
  const isMobile = useMobileMediaQuery();
  // Utils
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  // Internal Hooks

  // - Intercom
  const { showNewMessage: showNewMessageIntercom } = useIntercom();

  // - Axios
  const { requestCallback } = useAxios({});
  // - User
  const { users, isLoadingUsers } = useUsers({});

  const user = users?.at(0);

  const usersEmail = user?.email;
  const auth0UserId = user?.auth0UserId;
  const isSSO = Boolean(auth0UserId) && !auth0UserId?.includes('auth0');

  // - Base language
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const account = baseLanguage?.settings?.account ?? {};
  const {
    change_email: changeEmail,
    change_password: changePassword,
    preferences,
  } = account;
  const {
    change_email_button_label: changeEmailButtonLabel,
    section_title: changeEmailSectionTitle,
    sso_user_alert_message: ssoUserAlertMessageEmail,
    change_email_input_label: changeEmailInputLabel,
    change_email_description: changeEmailDescription,
  } = changeEmail ?? {};
  const {
    change_password_button_label: changePasswordButtonLabel,
    section_title: changePasswordSectionTitle,
    server_error_alert_text: serverErrorAlertTextPassword,
    sso_user_alert_message: ssoUserAlertMessagePassword,
    success_alert_text: successAlertTextPassword,
    change_password_description: changePasswordDescription,
  } = changePassword ?? {};
  const { section_title: preferencesSectionTitle } = preferences ?? {};

  // - Sanity organization
  const { organization } = useOrganization({
    organizationSlug,
    localeCode,
  });
  const organizationId = organization?.basic_info?.auth0_organization_id;

  // Local state
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const [alertEmail, setAlertEmail] =
    useState<Omit<SettingsAccountAlertProps, 'onExited'>>();
  const [alertPassword, setAlertPassword] =
    useState<Omit<SettingsAccountAlertProps, 'onExited'>>();

  // Effects
  useEffect(() => {
    if (!isSSO) return;
    setAlertEmail({
      severity: 'warning',
      message: ssoUserAlertMessageEmail,
      show: true,
    });
    setAlertPassword({
      severity: 'warning',
      message: ssoUserAlertMessagePassword,
      show: true,
    });
  }, [isSSO, ssoUserAlertMessageEmail, ssoUserAlertMessagePassword]);

  // Events
  const handlePasswordReset = useCallback(async () => {
    setLoadingButton(true);
    try {
      if (!REACT_APP_AUTH0_DOMAIN || !REACT_APP_AUTH0_CLIENT_ID) {
        throw new Error('Missing Env value');
      }
      const request = await requestCallback({
        method: 'POST',
        url: `https://${REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
        headers: { 'content-type': 'application/json' },
        data: {
          // eslint-disable-next-line camelcase
          client_id: REACT_APP_AUTH0_CLIENT_ID,
          email: usersEmail,
          connection: 'Username-Password-Authentication',
          organization: organizationId,
        },
      });
      if (request.status === 200) {
        setAlertPassword({
          severity: 'info',
          message: successAlertTextPassword,
          show: true,
        });
      }
      if (request.status >= 400) {
        console.log(request);
        setAlertPassword({
          severity: 'error',
          message: serverErrorAlertTextPassword,
          show: true,
        });
      }
    } catch (err: unknown) {
      console.log(err);
      setAlertPassword({
        severity: 'error',
        message: serverErrorAlertTextPassword,
        show: true,
      });
    } finally {
      setLoadingButton(false);
    }
  }, [
    organizationId,
    requestCallback,
    serverErrorAlertTextPassword,
    successAlertTextPassword,
    usersEmail,
  ]);

  const handleEmailChangeRequest = useCallback(() => {
    showNewMessageIntercom('I would like to change my account email address.');
  }, [showNewMessageIntercom]);

  if (isLoadingUsers) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column nowrap',
          alignItems: 'center',
        }}
      >
        <CircularProgress color="secondary" size={120} />;
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: { xs: 'stretch', md: 'flex-start' },
        gap: 2,
      }}
    >
      {/* Preferences */}
      <SettingsSubheader subheader={preferencesSectionTitle} />

      <AccountPreferencesForm />

      <Divider sx={{ alignSelf: 'stretch', my: 1 }} />

      {/* Email */}
      <SettingsSubheader subheader={changeEmailSectionTitle} />
      <SettingsAccountAlert {...alertEmail} />

      <Box sx={{ alignSelf: 'stretch' }}>
        <BaseOutlinedTextField
          label={usersEmail}
          inputProps={{
            sx: { width: { sm: `${usersEmail?.length}ch` } },
          }}
          fullWidth={isMobile}
          data-cy="settings_account_change-email-disabled-input"
          disabled
          defaultValue={usersEmail}
        />
      </Box>

      <SettingsDescription description={changeEmailDescription} />

      <BaseLoadingButton
        data-cy="settings_account_change-email-button"
        onClick={handleEmailChangeRequest}
        disabled={isSSO}
      >
        {changeEmailButtonLabel}
      </BaseLoadingButton>

      <Divider sx={{ alignSelf: 'stretch', my: 1 }} />

      {/* Password */}
      <SettingsSubheader subheader={changePasswordSectionTitle} />

      <SettingsDescription description={changePasswordDescription} />

      <SettingsAccountAlert {...alertPassword} />

      <BaseLoadingButton
        data-cy="settings_account_change-password-button"
        onClick={handlePasswordReset}
        loading={loadingButton}
        disabled={isSSO}
      >
        {changePasswordButtonLabel}
      </BaseLoadingButton>
    </Box>
  );
};

export default SettingsAccountPage;

interface SettingsAccountAlertProps {
  severity?: 'error' | 'info' | 'success' | 'warning';
  message?: string;
  show?: boolean;
}

const SettingsAccountAlert: FC<SettingsAccountAlertProps> = ({
  severity = 'warning',
  message,
  show,
}) => {
  return (
    <Grow in={show} timeout={'auto'} unmountOnExit>
      <Alert sx={{ width: '100%' }} severity={severity}>
        {message}
      </Alert>
    </Grow>
  );
};
