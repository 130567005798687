import { useProfiles } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import { theme } from '@guider-global/ui';
import { ThemeProvider, useTheme } from '@mui/material';
import { ProfilePictureForm } from 'forms';
import { EditProfileForm } from 'forms/EditProfileForm';
import { useLocalization } from 'hooks';
import { deepMerge } from 'utils';

export function SettingsProfilePage() {
  // Theme
  const organizationTheme = useTheme();
  const palette = deepMerge(theme.appTheme.palette, organizationTheme.palette);
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  // Profiles
  const { profiles } = useProfiles({});

  const profile = profiles?.at(0);

  return (
    <ThemeProvider theme={{ ...theme.appTheme, palette }}>
      <ProfilePictureForm localeCode={localeCode ?? ''} />
      <EditProfileForm profile={profile} localeCode={localeCode ?? ''} />
    </ThemeProvider>
  );
}
