// external
import { Typography } from '@mui/material';

export const SettingsCommunicationPage = () => (
  <>
    <Typography>
      This a placeholder for the Communication Settings page.
    </Typography>
  </>
);

export default SettingsCommunicationPage;
