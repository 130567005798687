// external
import { getSubDomain } from '@guider-global/front-end-utils';
import { useBaseLanguage } from '@guider-global/sanity-hooks/lib/hooks';
import { Box, Typography } from '@mui/material';
//components
import { IntegrationsList } from 'components';
import { useLocalization } from 'hooks';

export const SettingsIntegrationsPage = () => {
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);
  const { baseLanguage } = useBaseLanguage({ localeCode });

  return (
    <>
      <Box sx={{ display: 'flex', flexFlow: 'column nowrap', gap: 3 }}>
        <Typography variant="body2" component={'h3'} color="text.secondary">
          {baseLanguage?.settings?.integrations?.section_description}
        </Typography>
        <IntegrationsList />
      </Box>
    </>
  );
};
